import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../../../domain/theme';

export const AdFake = ({ width, height }) => {
  const lineDegrees = (Math.atan(height / width) * 180) / Math.PI;

  const styles = {
    adPlaceholder: css`
      width: ${width}px;
      height: ${height}px;
      position: relative;
      background-color: #f0f0f0;
      text-align: center;
      border: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    `,
    adBannerText: css`
      position: absolute;
      ${theme.font.family('boldWeb')};
      font-size: clamp(16px, 2.5vw, 42px);
      line-height: 1;
      letter-spacing: 3.6px;
      font-weight: bold;
      color: black;
      z-index: 2;
      background-color: #f0f0f0;
    `,

    adDimensions: css`
      position: absolute;
      ${theme.font.family('regularWeb')};
      font-size: clamp(12px, 1vw, 16px);
      bottom: 0;
      color: black;
    `,

    adLine: css`
      position: absolute;
      width: 150%;
      height: 0.5px;
      background-color: black;
      left: 0;
      transform-origin: top left;
    `,

    adLine1: css`
      top: 0;
      transform: rotate(${lineDegrees}deg);
    `,

    adLine2: css`
      bottom: 0;
      transform: rotate(-${lineDegrees}deg);
    `
  };

  return (
    <div css={styles.adPlaceholder}>
      <p css={styles.adBannerText}>AD BANNER</p>
      <p css={styles.adDimensions}>{`${width}px x ${height}px`}</p>
      <div css={[styles.adLine, styles.adLine1]} />
      <div css={[styles.adLine, styles.adLine2]} />
    </div>
  );
};

AdFake.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};
